import { all } from 'redux-saga/effects';
import { forkSagas as ForkAddressSagas } from './Address';
import { forkSagas as ForkOrderSagas } from './Order';
import { forkSagas as forkViewOrderSagas } from './ViewOrder';

export default function* rootSaga(context) {
  yield all([
    ...ForkAddressSagas(context),
    ...ForkOrderSagas(context),
    ...forkViewOrderSagas(context),
  ]);
}
