import React from 'react';
import styled from 'styled-components';
import colors from '../../../utils/colors';

const Title = styled.div`
  font-size: 2.5em;
  text-align: center;
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 10px 10px 0 0;
  border: 0px;
  padding: 4px 10px;
`;
const MainContainer = styled.div`
  width: 100%;
  background-color: ${colors.formBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const FormContainer = styled.div`
  width: 600px;
  max-width: calc(100vw - 10px);
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  background-color: ${colors.white};
  padding-bottom: 1rem;
  border-radius: 10px;
  border: 0px;
  margin: 5px;
`;

const Para = styled.div`
  padding: 30px 15px;
  margin-bottom: 100px;
  text-align: center;
`;

const OrderTaken = () => {
  return (
    <MainContainer>
      <FormContainer>
        <Title>Tilaus otettu</Title>
        <Para>
          Saat sähköpostiin tilauksen tarkemmat tiedot ja tilaus tulee näkymään
          myös työkalenteriisi.
        </Para>
      </FormContainer>
    </MainContainer>
  );
};

export default OrderTaken;
