import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../utils/colors';

const StyledButton = styled.button`
  padding: 0.4em 18px;
  height: 35px;
  margin: 0 1rem;
  border: 0;
  border-bottom: 1px solid ${colors.formBackground};
  background-color: ${colors.white};
  color: ${colors.primaryText};
  font-size: 14px;
  text-align: left;

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background-color: ${colors.secondaryText};
  }
`;

const ServiceItem = ({ service }) => {
  const text = service.cat + ' ' + service.name + ' - ' + service.price + '€';
  return <StyledButton disabled>{text}</StyledButton>;
};

ServiceItem.propTypes = {
  service: PropTypes.objectOf({
    id: PropTypes.number,
    name: PropTypes.string,
    cat: PropTypes.string,
    price: PropTypes.number,
  }),
};

export default ServiceItem;
