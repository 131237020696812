import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../utils/colors';

const Label = styled.label`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 4px;
  margin: 5px 25px 10px 25px;
  justify-content: flex-start;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  margin-top: 5px;
  opacity: 0;
  cursor: pointer;
  width: 16px;
  height: 18px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${colors.primary};
  stroke-width: 3px;
  height: 16px;
  width: 16px;
  position: relative;
  top: -6px;
  left: -1px;
`;

const Checkbox = styled.span`
  margin-top: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? colors.secondary : 'none')};
  border-radius: 3px;
  border: 1px solid ${colors.primary};
  transition: all 150ms;
  align-items: center;
  justify-content: center;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px #5c929f;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Text = styled.span`
  flex: 1;
  margin-left: 1em;
  color: ${colors.primaryText};
  text-align: left;
`;

const PhaseGoal = ({ text, onChange, checked }) => {
  return (
    <Label>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <Checkbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Checkbox>
      <Text>{text}</Text>
    </Label>
  );
};

PhaseGoal.propTypes = {
  checked: PropTypes.bool,
  text: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhaseGoal;
