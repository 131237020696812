import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import BaseInput from '../../baseComponents/baseInput';
import AddressActions from '../../../redux/Address/actions';

const Title = styled.div`
  max-width: 100vw;
  font-size: 18px;
  text-align: left;
  margin-left: 1rem;
  color: ${colors.white};
`;

const FormContainer = styled.div`
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    margin-top: 100px;
  }
`;

const FullWidthField = styled.div`
  display: flex;
  flex: 1;
`;

const AddressForm = () => {
  const dispatch = useDispatch();

  const { name, email, phone, address, postnumber, city } = useSelector(
    (state) => state.address,
  );

  const handleChangeName = (e) => {
    dispatch(AddressActions.setName(e.target.value));
  };

  const handleChangeEmail = (e) => {
    dispatch(AddressActions.setEmail(e.target.value));
  };

  const handleChangePhone = (e) => {
    dispatch(AddressActions.setPhone(e.target.value));
  };

  const handleChangeAddress = (e) => {
    dispatch(AddressActions.setAddress(e.target.value));
  };

  const handleChangePostnumber = (e) => {
    dispatch(AddressActions.setPostNumber(e.target.value));
  };

  const handleChangeCity = (e) => {
    dispatch(AddressActions.setCity(e.target.value));
  };

  return (
    <FormContainer>
      <Title>Tilausosoite ja yhteystiedot</Title>
      <FullWidthField>
        <BaseInput value={name} label="Nimi*" onChange={handleChangeName} />
      </FullWidthField>
      <FullWidthField>
        <BaseInput
          value={address}
          label="Katuosoite*"
          onChange={handleChangeAddress}
        />
      </FullWidthField>
      <FullWidthField>
        <BaseInput
          value={postnumber}
          label="Postinumero*"
          onChange={handleChangePostnumber}
        />
        <BaseInput value={city} label="Kaupunki*" onChange={handleChangeCity} />
      </FullWidthField>
      <FullWidthField>
        <BaseInput
          value={phone}
          label="Puhelin*"
          onChange={handleChangePhone}
        />
      </FullWidthField>
      <FullWidthField>
        <BaseInput
          value={email}
          label="Sähköposti*"
          onChange={handleChangeEmail}
        />
      </FullWidthField>
    </FormContainer>
  );
};

export default AddressForm;
