import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../utils/colors';
import ServiceActions from '../../../redux/Services/actions';
import ServiceItem from './ServiceItem';

const StyledButton = styled.button`
  height: 35px;
  margin: 0 1rem;
  border: 0;
  padding-left: 1rem;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid ${colors.formBackground};
  background-color: ${colors.white};

  &:first-of-type {
    border-radius: 10px 10px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 10px 10px;
  }

  &:hover {
    background-color: ${colors.secondaryText};
  }
`;

const Service = ({ items, name }) => {
  const { extendedService } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const ItemList = items.map((item) => {
    return (
      <ServiceItem
        key={item.id}
        id={item.id}
        name={item.name}
        cat={name}
        price={item.price}
      />
    );
  });

  const handleOnClickService = () => {
    if (extendedService === name) {
      dispatch(ServiceActions.setExtendedService('none'));
    } else {
      dispatch(ServiceActions.setExtendedService(name));
    }
  };

  const isExtended = extendedService === name;
  return (
    <>
      <StyledButton onClick={handleOnClickService} extended={isExtended}>
        {name}
      </StyledButton>
      {isExtended ? ItemList : ''}
    </>
  );
};

Service.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
};

export default Service;
