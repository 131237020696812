import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../../utils/colors';

const FieldContainer = styled.div`
  flex-direction: row;
  align-content: left;
  margin: 0px 25px 0px 25px;
`;

const PhaseText = styled.span`
  color: ${colors.primaryText};
  flex: 3;
  text-align: left;
`;

const Label = styled.label`
  flex: 1;
  text-align: left;
  margin: 5px 10px 0 0;
  font-weight: 500;
`;

const Field = ({ label, value }) => {
  return (
    <FieldContainer>
      <Label>{label}</Label>
      <PhaseText>{value}</PhaseText>
    </FieldContainer>
  );
};

Field.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default Field;
