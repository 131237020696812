import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Router, Route, Switch } from 'react-router-dom';
import ReactGa from 'react-ga';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import Landing from './components/landing';
import ViewOrder from './components/ViewOrder';
import '../node_modules/react-toastify/dist/ReactToastify.min.css';

const App = () => {
  const history = createBrowserHistory();
  ReactGa.initialize('UA-178460512-1');
  history.listen((location) => {
    ReactGa.set({ page: location.pathname });
    ReactGa.pageview(location.pathname);
  });
  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, [history]);
  return (
    // eslint-disable-next-line no-dupe-keys
    <main style={{ minHeight: '100vh', minHeight: '-webkit-fill-available' }}>
      <Router history={history}>
        <Switch>
          <Route path="/" component={Landing} exact />
          <Route
            path="/tarkastele-tilausta/:orderId/:viewerId"
            component={ViewOrder}
            exact
          />
        </Switch>
      </Router>
      <ToastContainer autoClose={2000} />
    </main>
  );
};

export default hot(App);
