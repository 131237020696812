const BASE = 'app/viewOrder/';

const LOAD_ORDER = BASE + 'LOAD_ORDER';
const SET_VIEWED_ORDER = BASE + 'SET_VIEWED_ORDER';
const SET_SELECTED_TIME = BASE + 'SET_SELECTED_TIME';
const SET_ORDER_AVAILABLE = BASE + 'SET_ORDER_AVAILABLE';
const SAVE_ORDER = BASE + 'SAVE_ORDER';
const SET_TERMS_ACCEPTED = BASE + 'SET_TERMS_ACCEPTED';
const CLAIM_ORDER = BASE + 'CLAIM_ORDER';
const CLAIM_ORDER_SUCCESS = BASE + 'CLAIM_ORDER_SUCCESS';
const CLAIM_ORDER_FAIL = BASE + 'CLAIM_ORDER_FAIL';
const SET_ORDER_ID = BASE + 'SET_ORDER_ID';
const SET_VIEWER_ID = BASE + 'SET_VIEWER_ID';

export default {
  LOAD_ORDER,
  SET_VIEWED_ORDER,
  SET_SELECTED_TIME,
  SET_ORDER_AVAILABLE,
  SAVE_ORDER,
  SET_TERMS_ACCEPTED,
  CLAIM_ORDER,
  CLAIM_ORDER_SUCCESS,
  CLAIM_ORDER_FAIL,
  SET_ORDER_ID,
  SET_VIEWER_ID,
};
