/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import colors from '../../utils/colors';
import ServiceSelector from './ServiceSelector/serviceSelector';
import SelectedServices from './SelectedServices';
import AddressForm from './AddressForm';
import TimeSuggestions from './TimeSuggestions';
import BaseButton from '../baseComponents/baseButton';
import ConfirmationModal from './ConfirmationModal';
import TermsOfUse from './TermsOfUse';
import OrderReady from './OrderReady';
import { OrderActions } from '../../redux/Order';
import backgroundImage from '../../assets/images/massage-background.jpg';

const Title = styled.div`
  font-size: 18px;
  text-align: left;
  margin: 1rem 1rem 0 1rem;
  color: ${colors.white};
`;

const PageHeader = styled.h1`
  font-size: 48px;
  color: white;
  padding: 0 1rem;
`;

const Pitch = styled.p`
  font-size: 24px;
  color: white;
  padding: 0 1rem;
`;

const MainContainer = styled.div`
  background-attachment: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-bottom: 70px;
  padding-top: 70px;
  justify-content: center;
  flex: 1;
  min-height: 100vh;
`;

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  min-height: 100vh;
`;

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  max-width: 480px;
  min-width: 320px;
  @media only screen and (min-width: 769px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const FormContainer = styled.div`
  min-width: 280px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
`;

const DescTitle = styled.div`
  display: flex;
  padding-left: 1rem;
  font-size: 14px;
  text-align: left;
  color: white;
  margin-bottom: 1rem;
`;

const FullWidthField = styled.div`
  display: block;
  width: 50%;
  padding: 0 1rem;
`;

const BackGround = styled.div`
  position: fixed;
  min-height: 100%;
  min-width: 100%;
  min-height: 100vh;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  margin: 0;
  @media only screen and (max-width: 768px) {
    background-size: auto 100vh;
  }

  @media only screen and (min-width: 769px) {
    background-size: ${({ width, height }) =>
    width > (height * 1.78)
      ? '100vw auto'
      : 'auto 100vh'};
  }
`;

const OrderProcessHelperTextContainer = styled.div`
  margin-top: 25px;
`;

const OrderProcessHelperText = styled.p`
  font-size: 18px;
  color: white;
  padding: 0 1rem;
`;

const Button = styled.button`
  font-size: 14px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.type === 'primary' ? colors.primary : colors.secondaryActive};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  color: ${colors.white};
  width: 12em;
  height: 36px;
  padding-left: 1em;
  padding-right: 1em;
  border: 0;
  margin-left: 1em;
`;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const Landing = () => {
  const dispatch = useDispatch();

  const { height, width } = useWindowDimensions();

  const next = () => {
    dispatch(OrderActions.setShowConfirmationModal(true));
  };

  const {
    address,
    order: { showConfirmationModal, isSent, showTermsOfUse, showOrderProcessHelperText },
    times: { timeSuggestions },
    services: { selectedServices },
  } = useSelector((state) => state);

  const canContinue = () => {
    return timeSuggestions.length > 0 && selectedServices.length > 0 &&
    address.name !== '' && address.email !== '' && address.phone !== '' &&
    address.address !== '' && address.postnumber !== '' && address.city !== '';
  };

  const toggleShowOrderProcessHelperText = () => {
    dispatch(OrderActions.setShowOrderProcessHelperText(!showOrderProcessHelperText));
  }

  if (isSent) {
    return <OrderReady />;
  }
  return (
    <PageWrapper>
      {showConfirmationModal && <ConfirmationModal />}
      {showTermsOfUse && <TermsOfUse />}
      <BackGround width={width} height={height} />
      <MainContainer>
        <ColumnContainer>
          <PageHeader>Hemmottele itseäsi ja tilaa hieroja kotiin!</PageHeader>
          <Pitch>
            Koe nopea, helppo ja vaivaton tapa tilata hieroja kotiin. Tilauksen
            tekemiseen menee vain muutama minuutti. Ja mitä parasta. Maksu
            hoituu helposti ja luotettavasti hieronnan yhdeydessä.
          </Pitch>
          <Pitch>Jos jännittää, tilaa Hieroja-Kotiin.fi</Pitch>
          <Button type="primary" onClick={toggleShowOrderProcessHelperText}>
            <span>
              Näin se toimii! { showOrderProcessHelperText ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </Button>
          { showOrderProcessHelperText && (
            <OrderProcessHelperTextContainer>
              <OrderProcessHelperText>Tehtyäsi tilauspyynnön viereisen lomakkeen kautta, lähetämme alueella toimiville hierojille tiedot tilauksesta. Hierojaksenne tulee ensimmäinen hieroja joka vahvistaa tilauksen kolmen tunnin sisällä. Saatte tekstiviestillä ilmoituksen kun hieroja vahvistaa tilauksen. </OrderProcessHelperText>
              <OrderProcessHelperText>Tilaus peruuntuu automaattisesti kolmen tunnin kuluttua mikäli kuitenkin käy niin, että yhdellekkään hierojalle ei tilauksen ajankohta sovi eikä täten vahvista tilausta. Voit aina tehdä uuden tilauspyynnön käyttäen eri ajankohtia</OrderProcessHelperText>
              <OrderProcessHelperText>Voit parantaa mahdollisuuksiasi saada hierojat vahvistamaan tilauspyyntösi kun annat mahdollisimman monta sopivaa ajankohtaa.</OrderProcessHelperText>
            </OrderProcessHelperTextContainer>
          )}
        </ColumnContainer>
        <ColumnContainer>
          <AddressForm />
          <FormContainer>
            <Title>Palvelut</Title>
            <DescTitle>Valitse hieronta (voit valita useamman)</DescTitle>
            <FormContainer>
              <ServiceSelector />
            </FormContainer>
            <Title>Hierontakäynnille valitut palvelut</Title>
            <SelectedServices />
          </FormContainer>
          <TimeSuggestions />
          <FormContainer>
            <FullWidthField>
              <BaseButton
                text="Seuraava"
                onClick={next}
                type="primary"
                disabled={!canContinue()}
              />
            </FullWidthField>
          </FormContainer>
        </ColumnContainer>
      </MainContainer>
    </PageWrapper>
  );
};

export default Landing;
