import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import BaseButton from '../../baseComponents/baseButton';
import { OrderActions } from '../../../redux/Order';
import colors from '../../../utils/colors';
import Field from './field';
import CheckBox from './CheckBox';
import Services from './Services';
import SuggestedTimes from './SuggestedTimes';

const Container = styled.div`
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
  align-self: center;
  position: relative;
  width: calc(100vw - 20px);
  max-width: 550px;
  background-color: ${colors.white};
  align-content: center;
  justify-content: center;
  text-align: left;
  color: ${colors.primaryText};
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  border-radius: 10px;
`;

const Title = styled.div`
  display: flex;
  height: 35px;
  width: 100%;
  text-align: center;
  color: ${colors.white};
  background-color: ${colors.primary};
  margin-bottom: 20px;
  padding: auto 10px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

const SmallTitle = styled.p`
  font-size: 16px;
  margin: 5px 25px 0 25px;
`;

const PhaseText = styled.p`
  margin: 20px 25px 0 25px;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primaryText};
`;

const TermsCont = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const Terms = styled.button`
  color: ${colors.primaryText};
  border: 0;
  padding: 0 0 0 4px;
  background: none;
  &:hover {
    color: ${colors.primary};
  }
`;

const DoubleFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin: 25px;
`;

const Space = styled.p`
  height: 10px;
`;

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const {
    address: { name, email, phone, address, postnumber, city },
    order: { termsAccepted, orderValidyAccepted, isSending, isSent },
  } = useSelector((state) => state);

  const showTermsOfUse = () => {
    dispatch(OrderActions.setShowTermsOfUse(true));
  };

  const orderAddress = `${address}, ${postnumber}, ${city}`;

  const allAccepted = () => {
    return termsAccepted && orderValidyAccepted;
  };

  const isLoading = () => {
    return isSending || isSent;
  };

  return (
    <Container>
      <Modal>
        <Title>Yhteenveto</Title>
        <SmallTitle>
          Olet luomassa seuraavanlaista hierontatilausta. Tarkistathan tilauksen
          vielä huolellisesti ennen kuin lähetät tilauksen.
        </SmallTitle>
        <PhaseText>Tilausosoite ja yhteystiedot:</PhaseText>
        <Field label="Nimi:" value={name} />
        <Field label="Sähköposti:" value={email} />
        <Field label="Puhelin:" value={phone} />
        <Field label="Tilausosoite:" value={orderAddress} />
        <PhaseText>Käynnille tilatut palvelut</PhaseText>
        <Services />
        <PhaseText>Ehdottamasi ajankohdat</PhaseText>
        <SuggestedTimes />
        <PhaseText>Maksuehdot</PhaseText>
        <SmallTitle>
          Maksetaan maksukortilla tai käteisellä (tasaraha) hieronnan
          yhteydessä.
        </SmallTitle>
        <Space />

        <CheckBox
          checked={termsAccepted}
          // eslint-disable-next-line prettier/prettier
          text={(
            <TermsCont>
              <span>Hyväksyn palvelun</span>
              <Terms onClick={showTermsOfUse}>käyttöehdot</Terms>
            </TermsCont>
            // eslint-disable-next-line prettier/prettier
          )}
          onChange={() =>
            dispatch(OrderActions.setTermsAccepted(!termsAccepted))
          }
        />
        <CheckBox
          checked={orderValidyAccepted}
          text="Ymmärrän, että tilaus on omalta osaltani sitova ja raukeaa mikäli yksikään hieroja ei kolmen tunnin kuluessa ota tilausta vastaan."
          onChange={() =>
            dispatch(OrderActions.setOrderValidyAccepted(!orderValidyAccepted))
          }
        />
        <DoubleFieldContainer>
          <BaseButton
            size="18"
            text="Takaisin"
            onClick={() =>
              dispatch(OrderActions.setShowConfirmationModal(false))
            }
            type="secondary"
          />
          <BaseButton
            size="18"
            text="Lähetä tilaus"
            onClick={() => dispatch(OrderActions.saveOrder())}
            disabled={!allAccepted() || isLoading()}
            type="primary"
            loading={isLoading()}
          />
        </DoubleFieldContainer>
      </Modal>
    </Container>
  );
};

export default ConfirmationModal;
