export default {
  white: '#FFFFFF',
  primary: '#FF9E44',
  primaryActive: '#863E10',
  secondary: '#495558,',
  secondaryActive: '#AAAAAA',
  primaryText: '#212529',
  secondaryText: '#D9E6EB',
  brown: ['#231717', '#302220', '#302220'],
  background: '#FFFFFF',
  formBackground: '#DDDDDD',
};
