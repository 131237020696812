import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import colors from '../../../utils/colors';
import ServiceActions from '../../../redux/Services/actions';

const StyledButton = styled.button`
  padding: 0.4em 18px;
  height: 35px;
  margin: 0 1rem;
  border: 0;
  border-bottom: 1px solid ${colors.formBackground};
  background-color: ${colors.white};
  color: ${colors.primaryText};
  font-size: 14px;
  text-align: left;
  flex-direction: row;
  display: flex;

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background-color: ${colors.secondaryText};
  }
`;

const Text = styled.div`
  flex: 9;
`;
const Close = styled.div`
  flex: 1;
  text-align: right;
  color: #999999;

  &:hover {
    color: black;
  }
`;

const ServiceItem = ({ service }) => {
  const dispatch = useDispatch();
  const text = service.cat + ' ' + service.name + ' - ' + service.price + '€';
  const handleOnClick = () => {
    dispatch(ServiceActions.removeService(service.id));
  };

  return (
    <StyledButton onClick={handleOnClick}>
      <Text>{text}</Text>
      <Close>
        <FontAwesomeIcon icon={faTimes} />
      </Close>
    </StyledButton>
  );
};

ServiceItem.propTypes = {
  service: PropTypes.objectOf({
    id: PropTypes.number,
    name: PropTypes.string,
    cat: PropTypes.string,
    price: PropTypes.number,
  }),
};

export default ServiceItem;
