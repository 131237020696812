import produce from 'immer';
import initialState from './initialState';
import types from './types';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_VIEWED_ORDER: {
      draft.orderLoaded = true;
      draft.timeSuggestions = data.timeSuggestions;
      draft.selectedServices = data.selectedServices;
      draft.address = data.address;
      draft.handler = data.handler;

      break;
    }

    case types.SET_SELECTED_TIME: {
      draft.selectedTime = data;

      break;
    }

    case types.SET_ORDER_ID: {
      draft.orderId = data;

      break;
    }

    case types.SET_VIEWER_ID: {
      draft.viewerId = data;

      break;
    }

    case types.SET_TERMS_ACCEPTED: {
      draft.termsAccepted = data;

      break;
    }

    case types.CLAIM_ORDER: {
      draft.isClaiming = true;

      break;
    }

    case types.CLAIM_ORDER_SUCCESS: {
      draft.isClaiming = false;
      draft.isClaimed = true;

      break;
    }

    case types.CLAIM_ORDER_FAIL: {
      draft.isClaiming = false;

      break;
    }

    default: {
      break;
    }
  }
}, initialState);
