import { toInteger } from 'lodash';
import axios from 'axios';
import { getPrettyDateTime } from '../helpers/helpers';
import { getMasseur } from '../staticData/masseurs';

// eslint-disable-next-line consistent-return
export default () => {
  return {
    createNewOrderEmail: async (order) => {
      const {
        address: { address, name, postnumber, city, phone },
        selectedServices,
        selectedTime,
        handler,
      } = order;

      const header = `<div><h2>Uusi tilaus</h2><p>Olet saanut uuden hierontatilauksen! Alla tilauksen tiedot.</p></div>`;
      const addressHtml = `<div class="addr"><h3>Osoite</h3><p>${name}</p><p>${address}</p><p>${postnumber}</p><p>${city}</p><p>${phone}</p></div>`;
      const servicesTexts = selectedServices.map((service) => {
        return `${service.cat} ${service.name} / ${service.price}€\n`;
      });

      const time = getPrettyDateTime(selectedTime);

      const services = selectedServices.map((service) => {
        return `<p>${service.cat} ${service.name} / ${service.price}€</p>`;
      });
      const servicesHtml = `<div><h3>Tilatut palvelut</h3>${services}</div>`;
      const timeHtml = `<div><h3>Ajankohta</h3>${time}</div>`;
      const html = `<html><body>${header}${addressHtml}${servicesHtml}${timeHtml}</body></html>`;

      const text = `Uusi tilaus!\n\n
        tilaaja: ${name}\n
        osoite: ${address}\n
        postinumero: ${postnumber}\n
        kaupunki: ${city}\n
        puhelin: ${phone}\n
        \n
        Tilatut palvelut:\n
        ${servicesTexts}\n
        Ajankohta:\n
        ${time}
      `;

      const subject = `Uusi hierontatilaus - ${time}`;

      const masseur = getMasseur(toInteger(handler));

      const to = masseur.email;

      return {
        html,
        text,
        subject,
        to,
      };
    },
    sendEmail: async (email) => {
      try {
        const response = await axios.post(
          '/.netlify/functions/sendEmail',
          email,
        );
        return response.status === 200;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return false;
      }
    },
  };
};
