export default {
  showConfirmationModal: false,
  termsAccepted: false,
  orderValidyAccepted: false,
  addressIsValid: false,
  addressValidyChecked: false,
  isSending: false,
  isSent: false,
  showTermsOfUse: false,
  showOrderProcessHelperText: false,
};
