import produce from 'immer';
import initialState from './initialState';
import types from './types';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_INPUT_DATETIME: {
      draft.datePicker = data;

      break;
    }

    case types.ADD_TIME_SUGGESTION: {
      if (draft.datePicker !== undefined) {
        draft.timeSuggestions.push(draft.datePicker);
      }

      break;
    }

    case types.REMOVE_TIME_SUGGESTION: {
      const index = draft.timeSuggestions.findIndex((time) => time === data);
      draft.timeSuggestions.splice(index, 1);

      break;
    }

    case types.RESET: {
      return initialState;
    }

    default: {
      break;
    }
  }
}, initialState);
