import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import BaseButton from '../../baseComponents/baseButton';
import { OrderActions } from '../../../redux/Order';
import colors from '../../../utils/colors';

const Container = styled.div`
  z-index: 4;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
`;

const Modal = styled.div`
  align-self: center;
  position: relative;
  padding-bottom: 24px;
  width: calc(100vw - 20px);
  max-width: 650px;
  background-color: ${colors.white};
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${colors.primaryText};
  max-height: calc(100vh - 20px);
  overflow-y: auto;
`;

const Title = styled.div`
  width: 100%;
  font-size: 1.5em;
  text-align: center;
  color: ${colors.white};
  background-color: ${colors.primary};
`;

const SmallTitle = styled.h4`
  font-size: 16px;
  margin: 20px 6%;
`;

const Paragraph = styled.p`
  margin: 20px auto 10px auto;
  font-size: 12px;
  color: ${colors.primaryText};
`;

const TermsOfUse = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Modal>
        <Title>
          Hieroja-kotiin.fi -Verkkosivun ja Välityspalvelujen yleiset
          käyttöehdot
        </Title>
        <SmallTitle>
          <p>(voimassa 31.5.2018 alkaen)</p>
          1. Käyttöehtojen soveltamisala ja hyväksyminen
        </SmallTitle>
        <Paragraph>
          Sote Kotiin Oy (jatkossa Yritys), Y-tunnus: 2886181-4, omistaa tämän
          Verkkosivun ja sillä olevat Välityspalvelut. Yrityksen kotipaikka on
          Helsinki. Näitä käyttöehtoja sovelletaan Yrityksen Verkkosivun
          käyttöön sekä kaikkiin Yrityksen Välityspalveluihin, ellei
          yksittäisistä palvelukohtaisista käyttöehdoista muuta johdu. Yritys
          tarjoaa Välityspalvelun, jossa yksittäiset Hierojat voivat tarjota
          Hierontapalveluita. Käyttämällä www.hieroja-kotiin.fi -Verkkosivua ja
          Yrityksen Välityspalveluja hyväksyt nämä Käyttöehdot sekä
          Tietosuojaselosteen ja sitoudut noudattamaan niitä. Mikäli et hyväksy
          näitä Käyttöehtoja sekä Tietosuojaselostetta, et ole oikeutettu
          käyttämään tätä Verkkosivua taikka sillä olevia Välityspalveluja.
          Mikäli Asiakas on alaikäinen, tulee hänen varmistaa, että hänen
          huoltajansa antaa suostumuksen Välityspalvelun käyttöön ja on
          tietoinen Käyttöehtojen sisällöstä.
        </Paragraph>
        <SmallTitle>2. Käyttöehtojen muuttaminen</SmallTitle>
        <Paragraph>
          Yritys pidättää oikeuden muuttaa näitä Käyttöehtoja milloin tahansa.
          Käyttöehtoihin tehtävistä muutoksista tiedotetaan Yhtiön Verkkosivulla
          ja ehdot tulevat voimaan välittömästi julkaisun jälkeen ja sitovat
          kaikkia Verkkosivun ja Välityspalvelujen käyttäjiä. Jatkamalla
          Verkkosivun ja Välityspalvelujen käyttämistä hyväksyt muutokset
          Käyttöehtoihin.
        </Paragraph>
        <SmallTitle>3. Verkkosivun ja Välityspalvelujen käyttäminen</SmallTitle>
        <SmallTitle>3.1 Tekniset vaatimukset</SmallTitle>
        <Paragraph>
          Yrityksen tarjoamilla Välityspalveluilla saattaa olla teknisiin
          valmiuksiin liittyviä erityisvaatimuksia. Verkkosivun ja
          Välityspalvelujen käyttäjä on itse vastuussa Verkkosivun ja
          Välityspalvelujen käyttöön liittyvien teknisten valmiuksen
          varmistamisesta. Yritys ei vastaa teknisistä syistä tai päivityksistä
          johtuvista tilapäisistä esteistä tai ongelmista Verkkosivun tai
          Välityspalvelujen saatavuuteen.
        </Paragraph>
        <SmallTitle>3.2 Rekisteröityminen</SmallTitle>
        <Paragraph>
          Eräiden Välityspalvelujen saatavuus saattaa edellyttää käyttäjäksi
          rekisteröitymistä sekä maksua kyseisestä Välityspalvelusta.
          Käyttäjäksi rekisteröityvä vakuuttaa antamansa tiedot oikeiksi.
          Väärien tietojen antaminen oikeuttaa Yrityksen irtisanomaan
          Välityspalvelun käyttöön oikeuttavan sopimuksen. Käyttäjäksi
          rekisteröityvältä kerättävät tiedot määritellään tarkemmin
          Tietosuojaselosteessa sekä mahdollisissa Välityspalvelukohtaisissa
          ehdoissa. Käyttäjäksi rekisteröitymisen yhteydessä käyttäjälle luodaan
          henkilökohtainen käyttäjätili, jolle kirjaudutaan salasanalla.
          Käyttäjältä edellytetään kirjautumistietojen säilyttämisen sekä
          sivullisille kertomisen osalta luottamuksellisuutta. Käyttäjätili on
          henkilökohtainen eikä sen Käyttöoikeutta saa myydä, vuokrata tai
          muutoin siirtää kolmannelle osapuolelle ilman erillistä etukäteistä,
          kirjallista suostumusta Yritykseltä. Käyttäjätilin luovuttaminen
          sivulliselle oikeuttaa Yrityksen irtisanomaan Välityspalvelun käyttöön
          oikeuttavan sopimuksen. Verkkosivun tai Välityspalvelun käyttäjällä ei
          ole tällöin oikeutta vahingonkorvaukseen tai maksettujen maksujen
          palautukseen. Verkkosivun tai Välityspalvelun käyttäjä on vastuussa
          väärinkäytöksen Yritykselle aiheuttamista vahingoista. Käyttäjä voi
          kuitenkin tilata Välityspalvelusta Hierontapalveluita suoritettavaksi
          muille henkilöille ja myös muuhun kuin omaan osoitteeseen.
        </Paragraph>
        <SmallTitle>
          3.3 Hierontapalvelujen tilaaminen ja tilauksen peruminen
        </SmallTitle>
        <Paragraph>
          Käyttäjä voi tilata Hierontapalveluja Yrityksen Välityspalvelun
          kautta. Tilaus katsotaan tarjoukseksi ostaa Hierontapalveluja
          Yrityksen ilmoittamien hintojen ja Käyttöehtojen mukaisesti. Sopimus
          on sitova, kun Käyttäjä saa tilausvahvistuksen, jossa ilmoitetaan
          tilauksen vastaanottamisesta. Tilausvahvistuksessa ilmoitetaan myös
          Hierojan henkilö, Hierontapalvelun suorittamisajankohta sekä –paikka.
          Hieroja vastaa Hierontapalvelun suorittamisesta, käytettävistä
          materiaaleista, Hierontapalvelun sujuvuudesta sekä mahdollisista
          Käyttäjälle aiheuttamistaan vahingoista. Käyttäjän on huolehdittava
          siitä, että Hierontapalvelujen suorittamiselle on varattu riittävät
          tilat. Käytännössä Hierontapalvelujen suorittaminen edellyttää noin 3
          x 2 metriä käsittävän tyhjän tilan. Käyttäjä voi perua
          vahvistamattoman tilauksen Välityspalvelussa ilman lisämaksua. Mikäli
          tilaus on vahvistettu, Käyttäjä voi perua tilauksen ilman lisämaksua
          vähintään 24 tuntia ennen tilausvahvistuksessa ilmoitettua
          Hierontapalvelun suorittamisajankohtaa. Mikäli Käyttäjä peruu
          tilauksen alle 24 tuntia tilausvahvistuksessa ilmoitetusta
          Hierontapalvelun suorittamisajankohdasta, peritään suorittamattomasta
          Hierontapalvelusta tilausvahvistuksessa ilmoitettu hinta laskua
          vastaan. Mikäli tilausvahvistuksessa ilmoitettu Hieroja ei
          ennalta-arvaamattomista syistä pääse paikalle tilausvahvistuksessa
          ilmoitettuna suorittamisajankohtana, ilmoittaa Yritys tästä
          Hierontapalvelun tilanneelle Käyttäjälle viipymättä. Yritys voi
          tarjota mahdollisuuksien mukaan eri Hierojaa tai uutta
          Hierontapalvelun suorittamisajankohtaa. Yritys ei vastaa
          Hierontapalvelun peruuntumisesta mahdollisesti aiheutuneista kuluista
          tai vahingoista.
        </Paragraph>
        <SmallTitle>3.4 Palvelun mainostaminen</SmallTitle>
        <Paragraph>
          Verkkosivun tai Välityspalvelun käyttäjä saa julkaista sosiaalisessa
          mediassa ja muussa mediassa linkkejä Verkkosivulle tai Välityspalvelun
          etusivulle siten kuin tämä voidaan katsoa hyväksyttäväksi,
          lainmukaiseksi eikä se ole omiaan loukkaamaan tai käyttämään hyväksi
          Yrityksen mainetta. Verkkosivun tai Välityspalvelun käyttäjä ei
          kuitenkaan saa esiintyä Yrityksen edustajana, työntekijänä, kumppanina
          tai muutoin liittyvänsä Yrityksen yritystoimintaan.
        </Paragraph>
        <SmallTitle>3.5 Hierontapalvelujen maksun suorittaminen</SmallTitle>
        <Paragraph>
          Maksullisten Hierontapalvelujen ostamisessa käytetään ulkoisen
          Välityspalveluntarjoajan iZettle- maksupalvelua, ellei
          palvelukohtaisista käyttöehdoista muuta johdu. Käytännössä maksu
          suoritetaan Hierojalle pankki- ja luottokortinlukijan välityksellä.
          Seuraavat kortit kelpaavat maksuvälineiksi: Visa, Visa Electron,
          Master Card, American Express, JCB, Maestro. Maksupalvelun omiin
          ehtoihin voi tutustua tällä sivulla: https://www.izettle.com/fi/terms
          Yritys ei vastaa mahdollisista katkoksista tai muista ongelmista
          ulkoisen palveluntarjoajan maksuliikennejärjestelmässä. Mikäli
          maksujärjestelmä ei Yrityksestä riippumattomista syistä ole
          toiminnallinen, lähettää Yritys Hierontapalvelun vastaanottaneelle
          Käyttäjälle laskun.
        </Paragraph>
        <SmallTitle>3.6 Tyytyväisyystakuu</SmallTitle>
        <Paragraph>
          Hierontapalveluita tarjoavat Hierojat ovat sitoutuneet 100 %
          tyytyväisyystakuuseen. Mikäli Käyttäjä ei ole tyytyväinen saamaansa
          Hierontapalveluun, ei maksua suoritetusta Hierontapalvelusta peritä.
        </Paragraph>
        <SmallTitle>
          4. Immateriaalioikeudet ja käyttäjän vastuu luvattomasta käytöstä
        </SmallTitle>
        <Paragraph>
          Yritys omistaa Verkkosivun ja Välityspalvelujen sisällön ja niiden
          tekijänoikeuslain tarkoittamat kaikki taloudelliset oikeudet, ellei
          toisin ole määritelty. Yritys antaa Käyttäjätilin haltijalle
          Välityspalvelukohtaisten käyttöehtojen mukaisen Käyttöoikeuden
          kyseiseen Välityspalveluun. Verkkosivun tai Välityspalvelun
          käyttäjällä ei ole oikeutta kopioida, luovuttaa, julkaista, saattaa
          yleisön saataviin, hyödyntää kaupallisesti eikä muullakaan tavalla
          käyttää Verkkosivun, Välityspalvelujen tai niiden sisältöihin
          sisältyviä tietokantoja tai ohjelmia muuten kuin näiden käyttöehtojen
          taikka Välityspalvelukohtaisten käyttöehtojen mukaisesti. Verkkosivun
          tai Välityspalvelun käyttäjällä ei ole oikeutta kerätä Verkkosivulta
          tai Välityspalveluista osakokonaisuuksia ja muodostaa niistä omia
          Välityspalvelu- tai aineistokokonaisuuksia. Sisällön käyttäminen tai
          hyödyntäminen tekijänoikeuslain sopimuslisenssiä koskevien säännösten
          perusteella on kielletty. Jos Yrityksellä on perusteltu syy epäillä,
          että Verkkosivun tai Välityspalvelun käyttäjä on toiminut näiden
          ehtojen, Tietosuojaselosteen taikka palvelukohtaisten käyttöehtojen
          vastaisesti, Yrityksellä on oikeus poistaa Käyttöoikeus tai ryhtyä
          muihin toimenpiteisiin siltä osin kuin se on tarpeellista
          Käyttöehtojen vastaisen toiminnan lopettamiseksi (ml. rikosilmoituksen
          tekeminen). Verkkosivun tai Välityspalvelun käyttäjällä ei ole tällöin
          oikeutta vahingonkorvaukseen tai maksettujen maksujen palautukseen.
          Verkkosivun tai Välityspalvelun käyttäjä on vastuussa väärinkäytöksen
          Yritykselle aiheuttamista vahingoista.
        </Paragraph>
        <SmallTitle>5. Henkilötietojen käsittely</SmallTitle>
        <Paragraph>
          Yritys käsittelee Käyttäjäksi rekisteröityneiden henkilötietoja näiden
          Käyttöehtojen, Tietosuojaselosteen, Välityspalvelukohtaisten
          käyttöehtojen sekä yksityisyydensuojaa koskevan lainsäädännön
          mukaisesti. Yritys voi käyttää ulkopuolisia Välityspalveluntarjoajia
          henkilötietojen hallinnointia varten Verkkosivun ja Välityspalvelujen
          päivitysten yhteydessä. Verkkosivu tai Välityspalvelut saattavat
          käyttää evästeitä (cookies).
        </Paragraph>
        <SmallTitle>6. Vastuu sisällöstä ja vastuunrajoitus</SmallTitle>
        <SmallTitle>6.1 Verkkosivu ja Välityspalvelu</SmallTitle>
        <Paragraph>
          Yritys päivittää Verkkosivua ja Välityspalveluja tarpeen sekä
          harkintansa mukaan. Osa Välityspalveluista saattaa olla staattisia
          eikä niitä päivitetä ollenkaan. Yritys ei kuitenkaan takaa
          Verkkosivulla ja Välityspalveluissa esitettyjen tietojen
          ajantasaisuutta eikä sisällön oikeellisuutta. Jollei pakottavasta
          lainsäädännöstä muuta johdu, Yritys ei vastaa mistään välittömästä tai
          välillisestä vahingosta, joka Verkkosivun tai Välityspalvelun
          käyttäjälle mahdollisesti aiheutuu Verkkosivun tai Välityspalvelun tai
          niiden sisällön käyttämisestä ja soveltamisesta.
        </Paragraph>
        <SmallTitle>
          6.2 Vastuu Hierontapalveluista ja vastuunrajoitus
        </SmallTitle>
        <Paragraph>
          Hierontapalvelujen tilaaminen ja niiden vastaanottaminen tapahtuu
          Käyttäjän omalla vastuulla. Yritys vastaa ainoastaan Välityspalvelun
          toimivuudesta eikä siten anna nimenomaisia, epäsuoria tai pakottavia
          tai muita takuita Hierontapalvelujen laadusta taikka sopivuudesta
          Käyttäjän tarkoitukseen. Yritys ei vastaa mistään välittömästä tai
          välillisestä vahingosta, joka aiheutuu Hierojan toimesta tai
          toimettomuudesta, Verkkosivujen käyttöhäiriöistä tai Hierojan neuvosta
          tai neuvottomuudesta. Yritys ei vastaa Verkkosivusta tai sen
          hallitsemilta verkkosivuilta ulkoisille sivustoille ohjautuvien
          linkkien toimivuudesta tai tällaisten sivujen sisällöstä.
        </Paragraph>
        <SmallTitle>7. Erotettavuus</SmallTitle>
        <Paragraph>
          Mikäli mikä tahansa näiden Käyttöehtojen osa todetaan laittomaksi tai
          täytäntöönpanokelvottomaksi, kyseinen osa erotetaan näistä
          Käyttöehdoista. Kaikki muut osat pysyvät voimassa kyseisen erottamisen
          vaikuttamatta niihin.
        </Paragraph>
        <SmallTitle>8. Sovellettava laki ja riitojen ratkaiseminen</SmallTitle>
        <Paragraph>
          Verkkosivun sekä Välityspalvelujen käyttöön ja näihin Käyttöehtoihin
          sovelletaan Suomen lakia. Verkkosivun tai Välityspalvelujen käytöstä
          mahdollisesti aiheutuvat riitaisuudet ratkaistaan Helsingin
          käräjäoikeudessa
        </Paragraph>
        <BaseButton
          size="18"
          text="Sulje"
          onClick={() => dispatch(OrderActions.setShowTermsOfUse(false))}
          type="secondary"
        />
      </Modal>
    </Container>
  );
};

export default TermsOfUse;
