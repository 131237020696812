import types from './types';

const setName = (data) => ({
  type: types.SET_NAME,
  data,
});

const setEmail = (data) => ({
  type: types.SET_EMAIL,
  data,
});

const setPhone = (data) => ({
  type: types.SET_PHONE,
  data,
});

const setAddress = (data) => ({
  type: types.SET_ADDRESS,
  data,
});

const setPostNumber = (data) => ({
  type: types.SET_POSTNUMBER,
  data,
});

const setCity = (data) => ({
  type: types.SET_CITY,
  data,
});

export default {
  setName,
  setEmail,
  setPhone,
  setAddress,
  setPostNumber,
  setCity,
};
