const BASE = 'app/address/';

const SET_NAME = BASE + 'SET_NAME';
const SET_ADDRESS = BASE + 'SET_ADDRESS';
const SET_POSTNUMBER = BASE + 'SET_POSTNUMBER';
const SET_CITY = BASE + 'SET_CITY';
const SET_EMAIL = BASE + 'SET_EMAIL';
const SET_PHONE = BASE + 'SET_PHONE';

export default {
  SET_NAME,
  SET_ADDRESS,
  SET_POSTNUMBER,
  SET_CITY,
  SET_EMAIL,
  SET_PHONE,
};
