const BASE = 'app/times/';

const ADD_TIME_SUGGESTION = BASE + 'ADD_TIME_SUGGESTION';
const REMOVE_TIME_SUGGESTION = BASE + 'REMOVE_TIME_SUGGESTION';
const SET_INPUT_DATETIME = BASE + 'SET_INPUT_DATETIME';
const RESET = BASE + 'RESET';

export default {
  ADD_TIME_SUGGESTION,
  REMOVE_TIME_SUGGESTION,
  SET_INPUT_DATETIME,
  RESET,
};
