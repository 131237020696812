import produce from 'immer';
import initialState from './initialState';
import types from './types';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_NAME: {
      draft.name = data;

      break;
    }

    case types.SET_EMAIL: {
      draft.email = data;

      break;
    }

    case types.SET_PHONE: {
      draft.phone = data;

      break;
    }

    case types.SET_ADDRESS: {
      draft.address = data;

      break;
    }

    case types.SET_POSTNUMBER: {
      draft.postnumber = data;

      break;
    }

    case types.SET_CITY: {
      draft.city = data;

      break;
    }

    default: {
      break;
    }
  }
}, initialState);
