import { fork, call, takeEvery, select, put, all } from 'redux-saga/effects';
import OrderActions from './actions';
import TimesActions from '../Times/actions';
import ServicesActions from '../Services/actions';
import types from './types';
import { getSelectedServicesTotalCost } from '../Services/selector';
import masseurs from '../../staticData/masseurs';
import { allReturned200 } from '../../helpers/helpers';

function* handleSaveOrder(context) {
  const { apiService, toast } = context;
  const {
    address,
    services: { selectedServices },
    times: { timeSuggestions },
    order: { termsAccepted },
  } = yield select((state) => state);

  const order = {
    address,
    selectedServices,
    timeSuggestions,
    termsAccepted,
  };

  const response = yield call(apiService.saveOrder, order);
  if (response.status === 200) {
    const {
      data: { createdOrderId },
    } = response;
    yield put(OrderActions.sendOrder({ createdOrderId, ...order }));
  } else {
    put(OrderActions.sendOrderFail());
    toast.error('Tilauksen lähetys epäonnistui.');
  }
}

function* handleSendOrder(context, order) {
  const { apiService, toast, ReactGa } = context;
  const responses = yield all(
    masseurs.map((masseur) => {
      const payload = { ...order.data, masseur };
      return call(apiService.sendOrderMessages, payload);
    }),
  );
  if (allReturned200(responses)) {
    toast.success('Tilaus lähetetty!');
    const orderTotal = yield select((state) =>
      getSelectedServicesTotalCost(state),
    );
    ReactGa.event({
      category: 'Order',
      action: 'Tilaus Lähetetty',
      value: orderTotal,
    });
    yield put(OrderActions.sendOrderSuccess());
  } else {
    put(OrderActions.sendOrderFail());
    toast.error('Tilauksen lähetys epäonnistui.');
  }
}

function* handleStartOver() {
  yield put(OrderActions.reset());
  yield put(TimesActions.reset());
  yield put(ServicesActions.reset());
}

function* watchStartOver(context) {
  yield takeEvery(types.START_OVER, handleStartOver, context);
}

function* watchSaveOrder(context) {
  yield takeEvery(types.SAVE_ORDER, handleSaveOrder, context);
}

function* watchSendOrder(context) {
  yield takeEvery(types.SEND_ORDER, handleSendOrder, context);
}

export default (context) => [
  fork(watchSaveOrder, context),
  fork(watchSendOrder, context),
  fork(watchStartOver, context),
];
