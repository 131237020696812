import { fork, call, takeEvery, select, put } from 'redux-saga/effects';
import ViewOrderActions from './actions';
import types from './types';
import { getMasseur } from '../../staticData/masseurs';
import {
  getPrettyDateTime,
  formatPhoneForSending,
} from '../../helpers/helpers';

function* handleSaveOrder(context) {
  const { apiService, toast } = context;
  const {
    address,
    services: { selectedServices },
    times: { timeSuggestions },
    order: { termsAccepted },
  } = yield select((state) => state);

  const order = {
    address,
    selectedServices,
    timeSuggestions,
    termsAccepted,
  };

  const response = yield call(apiService.saveOrder, order);
  if (response.status === 200) {
    /*
    const {
      data: { createdOrderId },
    } = response;
    yield put(OrderActions.sendOrder({ createdOrderId, ...order }));
    */
  } else {
    toast.error('Tilauksen lähetys epäonnistui.');
  }
}

function* handleLoadOrder(context, { data: { orderId, viewerId } }) {
  const { apiService, toast } = context;
  yield put(ViewOrderActions.setViewerId(viewerId));
  yield put(ViewOrderActions.setOrderId(orderId));
  const response = yield call(apiService.loadOrder, orderId);
  if (response.status === 200) {
    const { data } = response;
    yield put(ViewOrderActions.setViewedOrder(data));
  } else {
    toast.error('Tilauksen lataus epäonnistui.');
  }
}

function* handleClaimOrder(context) {
  const { apiService, toast, emailService } = context;
  const {
    viewOrder: {
      address,
      selectedServices,
      timeSuggestions,
      termsAccepted,
      orderId,
      viewerId,
      selectedTime,
    },
  } = yield select((state) => state);

  const order = {
    address,
    selectedServices,
    timeSuggestions,
    termsAccepted,
    handler: viewerId,
    orderId,
    selectedTime,
    status: 'CLAIMED',
  };

  const masseur = getMasseur(parseInt(viewerId, 10));
  const dateTimeString = getPrettyDateTime(selectedTime);
  const message = `${masseur.name} on vahvistanut tilauksesi ajalle: ${dateTimeString}. Saat sähköpostiisi (${address.email}) tilausvahvistuksen.\nMukavaa hierontaa!`;
  const messages = {
    numbers: [formatPhoneForSending(address.phone)],
    message,
  };

  const isProduction = process.env.NODE_ENV === 'production';
  const orderResponse = yield call(apiService.createNewOrder, {
    orderId,
    isProduction,
    masseur,
    selectedTime,
  });
  if (orderResponse.status === 200) {
    const response = yield call(apiService.saveOrder, order);
    if (response.status === 200) {
      const messageResponse = yield call(apiService.sendMessages, messages);
      if (messageResponse.status === 200) {
        toast.success('Tilaus otettu');
        const email = yield call(emailService.createNewOrderEmail, order);
        yield call(emailService.sendEmail, email);
        yield put(ViewOrderActions.claimOrderSuccess());
      } else {
        yield put(ViewOrderActions.claimOrderFail());
        toast.error(
          'Tilaus otettu, mutta viestin lähetys asiakkaalle epäonnistui',
        );
      }
    } else {
      yield put(ViewOrderActions.claimOrderFail());
      toast.error('Tilauksen ottaminen epäonnistui.');
    }
  } else {
    yield put(ViewOrderActions.claimOrderFail());
    toast.error('Tilauksen ottaminen epäonnistui.');
  }
}

function* watchSaveOrder(context) {
  yield takeEvery(types.SAVE_ORDER, handleSaveOrder, context);
}

function* watchLoadOrder(context) {
  yield takeEvery(types.LOAD_ORDER, handleLoadOrder, context);
}

function* watchclaimOrder(context) {
  yield takeEvery(types.CLAIM_ORDER, handleClaimOrder, context);
}

export default (context) => [
  fork(watchSaveOrder, context),
  fork(watchLoadOrder, context),
  fork(watchclaimOrder, context),
];
