/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../utils/colors';
import SelectedServices from './SelectedServices';
import AddressForm from './AddressForm';
import TimeSuggestions from './TimeSuggestions';
import BaseButton from '../baseComponents/baseButton';
import OrderTaken from './OrderTaken';
import { ViewOrderActions } from '../../redux/ViewOrder';
import backgroundImage from '../../assets/images/massage-background.jpg';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  min-height: 100vh;
`;

const Title = styled.div`
  font-size: 18px;
  text-align: left;
  padding-left: 1rem;
  color: ${colors.white};
`;

const PageHeader = styled.h1`
  font-size: 48px;
  color: white;
  padding: 0 1rem;
`;

const Pitch = styled.p`
  font-size: 24px;
  color: white;
  padding: 0 1rem;
`;

const MainContainer = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-attachment: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-bottom: 150px;
  padding-top: 100px;
  justify-content: center;
  flex: 1;

  @media only screen and (max-width: 768px) {
    background-size: auto 100vh;
  }

  @media only screen and (min-width: 769px) {
    background-size: ${({ width, height }) =>
    width > height * 1.78 ? '100vw auto' : 'auto 100vh'};
  }
  
`;

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  max-width: 480px;
  min-width: 320px;
  @media only screen and (min-width: 769px) {
    margin: 0 2rem 2rem 2rem;
  }
`;

const FormContainer = styled.div`
  min-width: 280px;
  max-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
`;

const FullWidthField = styled.div`
  display: block;
  width: 50%;
  padding: 0 1rem;
`;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};


const ViewOrder = () => {
  const dispatch = useDispatch();
  const {
    orderLoaded,
    handler,
    isClaiming,
    isClaimed,
    selectedTime,
  } = useSelector((state) => state.viewOrder);
  const { orderId, viewerId } = useParams();

  useEffect(() => {
    dispatch(ViewOrderActions.loadOrder({ orderId, viewerId }));
  }, []);

  const { height, width } = useWindowDimensions();

  const orderState = `${orderLoaded}-${handler === undefined}`;
  const claimOrder = () => {
    dispatch(ViewOrderActions.claimOrder());
  };

  const isDisabled = () => {
    return isClaimed || isClaiming || selectedTime === '';
  };

  if (isClaimed) {
    return <OrderTaken />;
  }

  return (
    <PageWrapper>
      {
        {
          'true-true': (
            <MainContainer width={width} height={height}>
              <ColumnContainer>
                <PageHeader>Tilauksen tarkastelu</PageHeader>
                <Pitch>
                  Valitse itsellesi sopiva aika listalta ja paina &quot;Ota
                  tilaus&quot;
                </Pitch>
              </ColumnContainer>
              <ColumnContainer>
                <AddressForm />
                <FormContainer>
                  <Title>Tilatut Palvelut</Title>
                  <SelectedServices />
                </FormContainer>
                <TimeSuggestions />
                <FormContainer>
                  <FullWidthField>
                    <BaseButton
                      text="Ota tilaus"
                      onClick={claimOrder}
                      size="28"
                      disabled={isDisabled()}
                      loading={isClaiming}
                      type="primary"
                    />
                  </FullWidthField>
                </FormContainer>
              </ColumnContainer>
            </MainContainer>
          ),
          'false-true': <div>loading</div>,
          'true-false': <div>Too late! Order already taken</div>,
        }[orderState]
      }
    </PageWrapper>
  );
};

export default ViewOrder;
