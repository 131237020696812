import { combineReducers } from 'redux';
import AddressTypes from './Address';
import ServicesTypes from './Services';
import TimesTypes from './Times';
import OrderTypes from './Order';
import ViewOrderTypes from './ViewOrder';

const rootReducer = combineReducers({
  address: AddressTypes,
  services: ServicesTypes,
  times: TimesTypes,
  order: OrderTypes,
  viewOrder: ViewOrderTypes,
});

export default rootReducer;
