import serviceList from '../../../staticData/services';
import Service from './Service';

const ServiceSelector = () => {
  const Services = serviceList.map((service) => {
    return (
      <Service key={service.name} name={service.name} items={service.items} />
    );
  });

  return Services;
};

export default ServiceSelector;
