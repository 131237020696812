import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import colors from '../../../utils/colors';
import 'react-datepicker/dist/react-datepicker.css';

const SuggestedTimesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SuggestedTime = styled.p`
  border: 0;
  background-color: ${colors.white};
  color: ${colors.primaryText};
  margin: 5px 25px 0 25px;
`;

const StyledPicker = styled.button`
  border: 1px solid ${colors.primaryText};
  border-radius: 4px 0 0 4px;
  padding-left: 2em;
  padding-right: 2em;
  background-color: ${colors.white};
  color: ${colors.primaryText};
`;

const DatePickerInput = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledPicker innerRef={ref} {...props}>
    {props.value}
  </StyledPicker>
));

DatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const SuggestedTimes = () => {
  const { timeSuggestions } = useSelector((state) => state.times);

  const suggestedTimes = timeSuggestions.map((t) => {
    const time = new Date(t);
    const dateTimeString =
      time.toLocaleDateString() +
      ' - ' +
      time.toLocaleTimeString().slice(0, -3);
    return <SuggestedTime key={dateTimeString}>{dateTimeString}</SuggestedTime>;
  });

  return <SuggestedTimesContainer>{suggestedTimes}</SuggestedTimesContainer>;
};

export default SuggestedTimes;
