export default [
  {
    name: 'Klassinen Hieronta',
    items: [
      { name: '60 min', id: 66, price: 75 },
      { name: '90 min', id: 67, price: 100 },
      { name: '120 min', id: 68, price: 130 },
    ],
  },
  {
    name: 'Urheiluhieronta',
    items: [
      { name: '60 min', id: 1298, price: 75 },
      { name: '90 min', id: 1303, price: 100 },
      { name: '120 min', id: 1300, price: 130 },
    ],
  },
];
