import types from './types';

const addTimeSuggestion = (data) => ({
  type: types.ADD_TIME_SUGGESTION,
  data,
});

const removeTimeSuggestion = (data) => ({
  type: types.REMOVE_TIME_SUGGESTION,
  data,
});

const setInputDatetime = (data) => ({
  type: types.SET_INPUT_DATETIME,
  data,
});

const reset = (data) => ({
  type: types.RESET,
  data,
});

export default {
  addTimeSuggestion,
  removeTimeSuggestion,
  setInputDatetime,
  reset,
};
