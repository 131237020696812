import types from './types';

const setShowConfirmationModal = (data) => ({
  type: types.SET_SHOW_CONFIRMATION_MODAL,
  data,
});

const setShowTermsOfUse = (data) => ({
  type: types.SET_SHOW_TERMS_OF_USE,
  data,
});

const setOrderValidyAccepted = (data) => ({
  type: types.SET_ORDER_VALIDY_ACCEPTED,
  data,
});

const checkAddressValidy = (data) => ({
  type: types.CHECK_ADDRESS_VALIDY,
  data,
});

const setAddressIsValid = (data) => ({
  type: types.SET_ADDRESS_IS_VALID,
  data,
});

const sendOrder = (data) => ({
  type: types.SEND_ORDER,
  data,
});

const saveOrder = (data) => ({
  type: types.SAVE_ORDER,
  data,
});

const sendOrderSuccess = (data) => ({
  type: types.SEND_ORDER_SUCCESS,
  data,
});

const sendOrderFail = (data) => ({
  type: types.SEND_ORDER_FAIL,
  data,
});

const setTermsAccepted = (data) => ({
  type: types.SET_TERMS_ACCEPTED,
  data,
});

const startOver = (data) => ({
  type: types.START_OVER,
  data,
});

const reset = (data) => ({
  type: types.RESET,
  data,
});

const setShowOrderProcessHelperText = (data) => ({
  type: types.SET_SHOW_ORDER_PROCESS_HELPER_TEXT,
  data,
});

export default {
  setShowOrderProcessHelperText,
  setShowConfirmationModal,
  setShowTermsOfUse,
  checkAddressValidy,
  setAddressIsValid,
  sendOrder,
  saveOrder,
  sendOrderSuccess,
  sendOrderFail,
  setTermsAccepted,
  setOrderValidyAccepted,
  startOver,
  reset,
};
