import produce from 'immer';
import initialState from './initialState';
import types from './types';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_SHOW_CONFIRMATION_MODAL: {
      draft.showConfirmationModal = data;

      break;
    }

    case types.SET_SHOW_TERMS_OF_USE: {
      draft.showTermsOfUse = data;

      break;
    }

    case types.SET_ADDRESS_IS_VALID: {
      draft.addressIsValid = data;

      break;
    }

    case types.CHECK_ADDRESS_VALIDY: {
      draft.addressValidyChecked = true;

      break;
    }

    case types.SET_TERMS_ACCEPTED: {
      draft.termsAccepted = data;

      break;
    }

    case types.SET_ORDER_VALIDY_ACCEPTED: {
      draft.orderValidyAccepted = data;

      break;
    }

    case types.SAVE_ORDER: {
      draft.isSending = true;

      break;
    }

    case types.SEND_ORDER_FAIL: {
      draft.isSending = false;

      break;
    }

    case types.SEND_ORDER_SUCCESS: {
      draft.isSending = false;
      draft.isSent = true;

      break;
    }

    case types.RESET: {
      return initialState;
    }

    case types.SET_SHOW_ORDER_PROCESS_HELPER_TEXT: {
      draft.showOrderProcessHelperText = data;

      break;
    }

    default: {
      break;
    }
  }
}, initialState);
