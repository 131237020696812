const BASE = 'app/service/';

const SET_EXTENDED_SERVICE = BASE + 'SET_EXTENDED_SERVICE';
const ADD_SERVICE = BASE + 'ADD_SERVICE';
const REMOVE_SERVICE = BASE + 'REMOVE_SERVICE';
const RESET = BASE + 'RESET';

export default {
  SET_EXTENDED_SERVICE,
  ADD_SERVICE,
  REMOVE_SERVICE,
  RESET,
};
