import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ServiceItem from './ServiceItem';
import colors from '../../../utils/colors';
import { getSelectedServicesTotalCost } from '../../../redux/Services/selector';

const Total = styled.div`
  text-align: left;
  color: ${colors.primaryText};
  margin: 0 25px;
`;

const ServiceSelector = () => {
  const { selectedServices } = useSelector((state) => state.services);
  const total = useSelector((state) => getSelectedServicesTotalCost(state));
  const Services = selectedServices.map((service, i) => {
    // eslint-disable-next-line react/no-array-index-key
    return <ServiceItem key={i} service={service} />;
  });

  return (
    <>
      {Services}
      <Total>Yhteensä: {total}</Total>
    </>
  );
};

export default ServiceSelector;
