import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import colors from '../../utils/colors';

const Button = styled.button`
  font-size: 14px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.type === 'primary' ? colors.primary : colors.secondaryActive};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  color: ${colors.white};
  min-width: 8em;
  height: 36px;
  padding-left: 3em;
  padding-right: 3em;
  border: 0;
`;

const BaseButton = ({ onClick, text, alt, size, disabled, type, loading }) => {
  return (
    <Button
      onClick={onClick}
      alt={alt}
      size={size}
      disabled={disabled}
      type={type}
    >
      {loading ? <FontAwesomeIcon icon={faSpinner} pulse /> : text}
    </Button>
  );
};

BaseButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

export default BaseButton;
