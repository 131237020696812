export default {
  orderLoaded: false,
  timeSuggestions: [],
  selectedServices: [],
  address: {},
  selectedTime: '',
  orderId: '',
  viewerId: undefined,
  handler: undefined,
  isClaiming: false,
  isClaimed: false,
};
