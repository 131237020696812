import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import fi from 'date-fns/locale/fi';
import { TimesActions } from '../../../redux/Times';
import colors from '../../../utils/colors';
import 'react-datepicker/dist/react-datepicker.css';
import './additional.css';

registerLocale('fi', fi);

const Title = styled.div`
  width: 100%;
  margin-top: 1rem;
  font-size: 18px;
  text-align: left;
  padding: 0 1rem;
  color: ${colors.white};
`;

const FormContainer = styled.div`
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const FullWidthField = styled.div`
  margin: 1rem 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
`;

const SuggestedTimesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 1rem 0;
`;

const SuggestedTimeButton = styled.button`
  display: flex;
  flex-direction: row;
  padding: 0.4em 1rem;
  margin: 0 1rem;
  border: 0;
  height: 35px;
  border-bottom: 1px solid ${colors.formBackground};
  background-color: ${colors.white};
  color: ${colors.primaryText};
  font-size: 14px;
  text-align: left;

  ${({ count }) =>
    count > 1 &&
    `
    &:first-of-type {
      border-radius: 10px 10px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 10px 10px;
    }
  `}
  ${({ count }) =>
    count < 2 &&
    `
    border-radius: 10px;
`}

  &:hover {
    background-color: ${colors.secondaryText};
  }
`;

const AddButton = styled.button`
  border: 0px;
  border-radius: 0 10px 10px 0;
  flex: 1;
  margin-right: 1rem;
  background-color: ${colors.primary};
  color: ${colors.white};
  font-size: 14px;
`;

const StyledPicker = styled.button`
  border: 0px;
  border-radius: 10px 0 0 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  height: 35px;
  width: calc(100% - 1rem);
  font-size: 14px;
  text-align: left;
  background-color: ${colors.white};
  color: ${colors.primaryText};
  min-width: 240px;
`;

const DescTitle = styled.div`
  display: flex;
  padding-left: 1rem;
  font-size: 14px;
  text-align: left;
  color: white;
`;

const Text = styled.div`
  flex: 9;
`;

const Close = styled.div`
  flex: 1;
  text-align: right;
  color: #999999;

  &:hover {
    color: black;
  }
`;

const DatePickerInput = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledPicker innerRef={ref} {...props}>
    {props.value}
  </StyledPicker>
));

DatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

const TimeSuggestions = () => {
  const { datePicker, timeSuggestions } = useSelector((state) => state.times);
  const dispatch = useDispatch();

  const handleOnChange = (date) => {
    dispatch(TimesActions.setInputDatetime(date));
  };

  const handleOnClick = () => {
    dispatch(TimesActions.addTimeSuggestion());
  };

  const selected = datePicker === undefined ? new Date() : new Date(datePicker);

  const SuggestedTimes = timeSuggestions.map((t) => {
    const time = new Date(t);
    const dateTimeString =
      time.toLocaleDateString() +
      ' - ' +
      time.toLocaleTimeString().slice(0, -3);
    return (
      <SuggestedTimeButton
        count={timeSuggestions.length}
        key={dateTimeString}
        onClick={() => dispatch(TimesActions.removeTimeSuggestion(t))}
      >
        <Text>{dateTimeString}</Text>
        <Close>
          <FontAwesomeIcon icon={faTimes} />
        </Close>
      </SuggestedTimeButton>
    );
  });

  return (
    <FormContainer>
      <Title>Sopivat ajankohdat</Title>
      <DescTitle>Valitse sinulle sopivia ajankohtia 1-10 kpl</DescTitle>
      <FullWidthField>
        <DatePicker
          customInput={<DatePickerInput />}
          locale="fi"
          showTimeSelect
          selected={selected}
          onChange={handleOnChange}
          dateFormat="dd.MM.yyyy HH:mm"
          timeCaption="Aika"
        />
        <AddButton onClick={handleOnClick}>Lisää aika</AddButton>
      </FullWidthField>
      <Title>Ehdottamasi ajankohdat</Title>
      <SuggestedTimesContainer>{SuggestedTimes}</SuggestedTimesContainer>
    </FormContainer>
  );
};

export default TimeSuggestions;
