import types from './types';

const setExtendedService = (data) => ({
  type: types.SET_EXTENDED_SERVICE,
  data,
});

const addService = (data) => ({
  type: types.ADD_SERVICE,
  data,
});

const removeService = (data) => ({
  type: types.REMOVE_SERVICE,
  data,
});

const reset = (data) => ({
  type: types.RESET,
  data,
});

export default {
  setExtendedService,
  addService,
  removeService,
  reset,
};
