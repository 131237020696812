import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Item from './Item';
import colors from '../../../utils/colors';
import { getOrderTotal } from '../../../redux/ViewOrder/selector';

const Total = styled.div`
  margin: 1rem 1rem 0 1rem;
  padding: 0.4em 18px;
  height: 35px;
  background-color: ${colors.white};
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  border: 0px;
  border-bottom: ${(props) =>
    props.count > 0 ? '1px solid ' + colors.secondaryText : '0px'};
  border-radius: 8px;
  border-radius: ${(props) => (props.count > 0 ? '8px 8px 0 0' : '8px')};
  color: ${colors.primaryText};
`;

const ServiceSelector = () => {
  const { selectedServices } = useSelector((state) => state.viewOrder);
  const total = useSelector((state) => getOrderTotal(state));
  const Services = selectedServices.map((service, i) => {
    // eslint-disable-next-line react/no-array-index-key
    return <Item key={i} service={service} />;
  });

  return (
    <>
      <Total count={Services.length}>Yhteensä: {total}</Total>
      {Services}
    </>
  );
};

export default ServiceSelector;
