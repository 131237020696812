import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../utils/colors';
import ServiceActions from '../../../redux/Services/actions';

const StyledButton = styled.button`
  padding: 0.4em 1rem;
  border: 0;
  margin: 0 1rem;
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid ${colors.formBackground};
  background-color: ${colors.white};
  color: ${colors.primaryText};

  &:last-of-type {
    border-radius: 0 0 10px 10px;
  }

  &:hover {
    background-color: ${colors.secondaryText};
  }
`;

const ServiceItem = ({ id, name, cat, price }) => {
  const dispatch = useDispatch();

  const handleOnClickItem = () => {
    dispatch(ServiceActions.addService({ cat, id, name, price }));
  };

  const text = name + ' - ' + price + '€';

  return <StyledButton onClick={handleOnClickItem}>{text}</StyledButton>;
};

ServiceItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  cat: PropTypes.string,
  price: PropTypes.number,
};

export default ServiceItem;
