import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../utils/colors';

const BaseInput = styled.input`
  font-size: 14px;
  border-radius: 8px;
  border: 0px solid #999999;
  background-color: #ffffff;
  color: ${colors.primaryText};
  width: 100%;
  text-align: left;
  text-align-last: left;
  padding: 0.25em;
  padding-left: 20px;
  padding-right: 20px;
  align-self: center;
  height: 35px;
`;

const Container = styled.div`
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const baseSelector = ({ label, value, onChange, id, type, readOnly }) => {
  return (
    <Container>
      <BaseInput
        id={id}
        onChange={onChange}
        value={value}
        type={type}
        placeholder={label}
        readOnly={readOnly}
      />
    </Container>
  );
};

baseSelector.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default baseSelector;
