import axios from 'axios';

// eslint-disable-next-line consistent-return
const saveOrder = async (order, orderId) => {
  try {
    return await axios.post('/.netlify/functions/saveOrder', order, orderId);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

// eslint-disable-next-line consistent-return
const loadOrder = async (data) => {
  try {
    return await axios.get('/.netlify/functions/loadOrder', {
      params: { data },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

// eslint-disable-next-line consistent-return
const sendOrderMessages = async (order) => {
  try {
    return await axios.post('/.netlify/functions/sendOrderMessages', order);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

// eslint-disable-next-line consistent-return
const sendMessages = async (message) => {
  try {
    return await axios.post('/.netlify/functions/sendMessage', message);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

// eslint-disable-next-line consistent-return
const createNewOrder = async (payload) => {
  try {
    return await axios.post('/.netlify/functions/createNewOrder', payload);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export default {
  saveOrder,
  loadOrder,
  sendOrderMessages,
  sendMessages,
  createNewOrder,
};
