import produce from 'immer';
import initialState from './initialState';
import types from './types';

// eslint-disable-next-line consistent-return
export default produce((draft, { type, data }) => {
  switch (type) {
    case types.SET_EXTENDED_SERVICE: {
      draft.extendedService = data;

      break;
    }

    case types.ADD_SERVICE: {
      draft.selectedServices.push(data);

      break;
    }

    case types.REMOVE_SERVICE: {
      const index = draft.selectedServices.findIndex(
        (service) => service.id === data,
      );
      draft.selectedServices.splice(index, 1);

      break;
    }

    case types.RESET: {
      return initialState;
    }

    default: {
      break;
    }
  }
}, initialState);
