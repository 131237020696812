/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ViewOrderActions } from '../../../redux/ViewOrder';
import colors from '../../../utils/colors';
import 'react-datepicker/dist/react-datepicker.css';

const Title = styled.div`
  font-size: 18px;
  text-align: left;
  margin: 1rem;
  color: ${colors.white};
`;

const FormContainer = styled.div`
  min-width: 280px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const SuggestedTimesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SuggestedTimeButton = styled.button`
  padding: 0.4em 1rem;
  margin: 0 1rem;
  border: 0;
  height: 35px;
  border-bottom: 1px solid ${colors.formBackground};
  background-color: ${colors.white};
  color: ${colors.primaryText};
  font-size: 14px;
  text-align: left;

  ${({ count }) =>
    count > 1 &&
    `
    &:first-of-type {
      border-radius: 10px 10px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 10px 10px;
    }
  `}
  ${({ count }) =>
    count < 2 &&
    `
    border-radius: 10px;
`}

  &:hover {
    background-color: ${colors.secondaryText};
  }
`;

const TimeSuggestions = () => {
  const dispatch = useDispatch();
  const { timeSuggestions, selectedTime } = useSelector(
    (state) => state.viewOrder,
  );

  const SuggestedTimes = timeSuggestions.map((t) => {
    const time = new Date(t);
    const dateTimeString =
      time.toLocaleDateString() +
      ' - ' +
      time.toLocaleTimeString().slice(0, -3);
    return (
      <SuggestedTimeButton
        key={dateTimeString}
        onClick={() => dispatch(ViewOrderActions.setSelectedTime(t))}
        selected={selectedTime === t}
        count={timeSuggestions.length}
      >
        {dateTimeString}
      </SuggestedTimeButton>
    );
  });

  return (
    <FormContainer>
      <Title>Valitse aika</Title>
      <SuggestedTimesContainer>{SuggestedTimes}</SuggestedTimesContainer>
    </FormContainer>
  );
};

export default TimeSuggestions;
