export const getPrettyDateTime = (t) => {
  const time = new Date(t);
  const dateTimeString =
    time.toLocaleDateString() + ' - ' + time.toLocaleTimeString().slice(0, -3);

  return dateTimeString;
};

export const allReturned200 = (responses) => {
  let count = 0;
  responses.forEach((response) => {
    if (response.status === 200) {
      count += 1;
    }
  });
  return count === responses.length;
};

export const formatPhoneForSending = (phone) => {
  let number = phone.replace(/[^\d]/g, '');
  if (number[0] === '0') {
    number = number.slice(1);
  }
  if (number.indexOf('358') < 0) {
    return '358' + number;
  }
  return number;
};
