import { useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import BaseInput from '../../baseComponents/baseInput';

const Title = styled.div`
  font-size: 18px;
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  color: ${colors.white};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const FullWidthField = styled.div`
  display: flex;
  flex: 1;
`;

const AddressForm = () => {
  const { name, address, postnumber, city } = useSelector(
    (state) => state.viewOrder.address,
  );

  return (
    <FormContainer>
      <Title>Tilausosoite ja yhteystiedot</Title>
      <FullWidthField>
        <BaseInput disabled value={name} label="Nimi" readOnly />
      </FullWidthField>
      <FullWidthField>
        <BaseInput disabled value={address} label="Katuosoite" readOnly />
      </FullWidthField>
      <FullWidthField>
        <BaseInput disabled value={postnumber} label="Postinumero" readOnly />
        <BaseInput disabled value={city} label="Kaupunki" readOnly />
      </FullWidthField>
    </FormContainer>
  );
};

export default AddressForm;
