const BASE = 'app/order/';

const SET_SHOW_CONFIRMATION_MODAL = BASE + 'SET_SHOW_CONFIRMATION_MODAL';
const SET_SHOW_TERMS_OF_USE = BASE + 'SET_SHOW_TERMS_OF_USE';
const CHECK_ADDRESS_VALIDY = BASE + 'CHECK_ADDRESS_VALIDY';
const SET_ADDRESS_IS_VALID = BASE + 'SET_ADDRESS_IS_VALID';
const SEND_ORDER = BASE + 'SEND_ORDER';
const SEND_ORDER_SUCCESS = BASE + 'SEND_ORDER_SUCCESS';
const SEND_ORDER_FAIL = BASE + 'SEND_ORDER_FAIL';
const SAVE_ORDER = BASE + 'SAVE_ORDER';
const SET_TERMS_ACCEPTED = BASE + 'SET_TERMS_ACCEPTED';
const SET_ORDER_VALIDY_ACCEPTED = BASE + 'SET_ORDER_VALIDY_ACCEPTED';
const START_OVER = BASE + 'START_OVER';
const RESET = BASE + 'RESET';
const SET_SHOW_ORDER_PROCESS_HELPER_TEXT =
  BASE + 'SET_SHOW_ORDER_PROCESS_HELPER_TEXT';

export default {
  SET_SHOW_ORDER_PROCESS_HELPER_TEXT,
  SET_SHOW_CONFIRMATION_MODAL,
  SET_SHOW_TERMS_OF_USE,
  CHECK_ADDRESS_VALIDY,
  SET_ADDRESS_IS_VALID,
  SEND_ORDER,
  SEND_ORDER_SUCCESS,
  SEND_ORDER_FAIL,
  SAVE_ORDER,
  SET_TERMS_ACCEPTED,
  SET_ORDER_VALIDY_ACCEPTED,
  START_OVER,
  RESET,
};
