import types from './types';

const loadOrder = (data) => ({
  type: types.LOAD_ORDER,
  data,
});

const setViewedOrder = (data) => ({
  type: types.SET_VIEWED_ORDER,
  data,
});

const setSelectedTime = (data) => ({
  type: types.SET_SELECTED_TIME,
  data,
});

const setOrderAvailable = (data) => ({
  type: types.SET_ORDER_AVAILABLE,
  data,
});

const saveOrder = (data) => ({
  type: types.SAVE_ORDER,
  data,
});

const claimOrder = (data) => ({
  type: types.CLAIM_ORDER,
  data,
});

const claimOrderSuccess = (data) => ({
  type: types.CLAIM_ORDER_SUCCESS,
  data,
});

const claimOrderFail = (data) => ({
  type: types.CLAIM_ORDER_FAIL,
  data,
});

const setTermsAccepted = (data) => ({
  type: types.SET_TERMS_ACCEPTED,
  data,
});

const setViewerId = (data) => ({
  type: types.SET_VIEWER_ID,
  data,
});

const setOrderId = (data) => ({
  type: types.SET_ORDER_ID,
  data,
});

export default {
  loadOrder,
  setViewedOrder,
  setSelectedTime,
  setOrderAvailable,
  saveOrder,
  claimOrder,
  claimOrderSuccess,
  claimOrderFail,
  setTermsAccepted,
  setViewerId,
  setOrderId,
};
