const isProduction = process.env.NODE_ENV === 'production';

const prodMasseurs = [
  {
    id: 2,
    phone: '358505436662',
    name: 'Santtu Skonbäck',
    email: 'sskonbac@gmail.com',
  },
  {
    id: 7,
    phone: '358405638471',
    name: 'Leena Mikkola',
    email: 'leenamikkolastudio@gmail.com',
  },
  {
    id: 87,
    phone: '358407012733',
    name: 'Jarno Vaajoki',
    email: 'jarno.vaajoki@gmail.com',
  },
  {
    id: 125,
    phone: '358443008275',
    name: 'Kristian Jansson',
    email: 'krja86@gmail.com',
  },
];

const devMasseurs = [
  {
    id: 2,
    phone: '358505436662',
    name: 'Santtu Skonbäck',
    email: 'sskonbac@gmail.com',
  },
];

const masseurs = isProduction ? prodMasseurs : devMasseurs;

export const getMasseur = (id) => {
  return masseurs[masseurs.findIndex((m) => m.id === id)];
};

export default masseurs;
