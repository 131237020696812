import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../utils/colors';
import ServiceActions from '../../../redux/Services/actions';

const StyledButton = styled.p`
  padding: 0;
  border: 0;
  margin: 5px 25px 6px 25px;
  background-color: ${colors.white};
  color: ${colors.primaryText};
`;

const ServiceItem = ({ service }) => {
  const dispatch = useDispatch();
  const text = service.cat + ' ' + service.name + ' - ' + service.price + '€';
  const handleOnClick = () => {
    dispatch(ServiceActions.removeService(service.id));
  };
  return <StyledButton onClick={handleOnClick}>{text}</StyledButton>;
};

ServiceItem.propTypes = {
  service: PropTypes.objectOf({
    id: PropTypes.number,
    name: PropTypes.string,
    cat: PropTypes.string,
    price: PropTypes.number,
  }),
};

export default ServiceItem;
