import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import ReactGa from 'react-ga';
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import apiService from '../services/apiService';
import getEmailService from '../services/emailService';

const emailService = getEmailService();

const context = { ReactGa, apiService, toast, emailService };

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const isProduction = process.env.NODE_ENV === 'production';
const options = {
  trace: true,
};

const persistConfig = {
  blacklist: ['viewOrder', 'timesuggestions', 'selectedServices', 'order'],
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools(options);

const middlewareEnhancer = isProduction
  ? applyMiddleware(...middlewares)
  : composeEnhancers(applyMiddleware(...middlewares));

const enhancers = [middlewareEnhancer];
const composedEnhancers = compose(...enhancers);

const store = {
  ...createStore(persistedReducer, undefined, composedEnhancers),
  runSaga: sagaMiddleware.run(rootSaga, context),
};

export const persistor = persistStore(store);

export default store;
